import * as React from 'react';
import { Stack } from '@mui/material';
import './TrueFalse4.scss';
import { useState } from 'react';
import StyledButton from '../../../components/StyledButton/StyledButton';
import AudioBox from '../../../components/Audio/Audio';

function TrueFalse4({ ex }) {
  const [selectedAnswers, setSelectedAnswers] = useState([]);
  const [correctAnswer, setCorrectAnswer] = useState([]);
  const [failedAnswer, setFailedAnswer] = useState([]);
  const [isCheck, setIsCheck] = useState(false);
  const [currentAudio, setCurrentAudio] = useState(ex.record ? ex.record : null);
  const [showAnswers, setShowAnswer] = React.useState(false);
  const [questionWithAnswer, setQuestionWithAnswer] = React.useState();

  const GetExerciseWithAnswer = () => {
    const questionList = [...ex.questions];
    const questionsWithAnswer = questionList.map((ques) => {
      return {
        ord: ques.ordinalNumber,
        content: ques.content,
      };
    });
    setQuestionWithAnswer(questionsWithAnswer);
  };

  React.useEffect(() => {
    setSelectedAnswers([]);
    setCorrectAnswer([]);
    setFailedAnswer([]);
    setIsCheck(false);
    setCurrentAudio(ex.record ? ex.record : null);
    GetExerciseWithAnswer();
  }, [ex]);

  const handleSelectAnswer = (questionId, answerItem) => {
    !isCheck &&
      setSelectedAnswers((prevState) => {
        const updatedAnswers = prevState.map((answer) => {
          if (answer.id === questionId) {
            return { id: questionId, answer: answerItem };
          }
          return answer;
        });
        const isNewAnswer = !prevState.some((answer) => answer.id === questionId);
        if (isNewAnswer) {
          updatedAnswers.push({ id: questionId, answer: answerItem });
        }
        return updatedAnswers;
      });
  };

  const handleCheckAnswer = () => {
    setIsCheck(true);
    setCurrentAudio(null);
    const correctAnswers = ex.questions.map((answer) => ({ id: answer.id, answer: answer.answer }));
    setCorrectAnswer(correctAnswers);
    const nullAnswers = ex.questions.filter(
      (item) => !selectedAnswers.some((answer) => answer.id === item.id),
    );
    const failedAnswers = selectedAnswers.filter((answer) => {
      return ex.questions.some((item) => item.id === answer.id && answer.answer !== item.answer);
    });
    setFailedAnswer([...failedAnswers, ...nullAnswers]);
  };

  return (
    // Dạng lấy ảnh làm trung tâm # TrueFalse2 lấy text làm trung tâm
    <>
      {ex.record && <AudioBox exeAudio={currentAudio} />}

      <div id="true-false-4-wrapper">
        {/* Question */}
        <table className="table-container">
          <tbody>
            {ex.questions.length > 0 &&
              ex.questions.map((question) => {
                return (
                  <tr className="question" key={question.id}>
                    <td className="ordinal-number">{question.ordinalNumber}.</td>
                    <td className="image">
                      <img src={question.images} alt="question" className="image-item" />
                      <p className="content" style={{ opacity: showAnswers ? 1 : 0 }}>
                        {question.content}
                      </p>
                    </td>
                    <td className="box-wrapper">
                      <div
                        style={{
                          height: '100%',
                          display: 'flex',
                          justifyContent: 'end',
                        }}
                      >
                        <p
                          className={`true-box ${
                            selectedAnswers.some(
                              (answer) => answer.id === question.id && answer.answer === 'true',
                            )
                              ? 'clicked'
                              : ''
                          }`}
                          onClick={() => handleSelectAnswer(question.id, 'true')}
                        />
                        <p
                          className={`false-box ${
                            selectedAnswers.some(
                              (answer) => answer.id === question.id && answer.answer === 'false',
                            )
                              ? 'clicked'
                              : ''
                          }`}
                          onClick={() => handleSelectAnswer(question.id, 'false')}
                        />
                      </div>
                    </td>
                    {isCheck &&
                      (failedAnswer.some((answer) => answer.id === question.id) ? (
                        <td className="answer false">✘</td>
                      ) : (
                        <td className="answer true">✔</td>
                      ))}
                  </tr>
                );
              })}
          </tbody>
        </table>

        <Stack direction="row" className="button">
          <StyledButton onClick={handleCheckAnswer} disabled={isCheck} content="Kiểm tra" />
          <div style={{ marginLeft: '2rem' }}>
            <StyledButton
              onClick={() => setShowAnswer(!showAnswers)}
              content={!!showAnswers ? 'Đóng script' : 'Xem script'}
              type="border-accent"
            />
          </div>
        </Stack>
      </div>
    </>
  );
}

export default TrueFalse4;
